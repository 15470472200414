[x-cloak] { display: none; }

html,
body {
    @apply font-humboldt-light text-brown-900;
    font-display: swap;
}

@font-face {
    font-family: 'GT Walsheim';
    font-display: swap;
    src: url('/assets/site/fonts/gt-walsheim-web.ttf') format('truetype');
}

@font-face {
    font-family: 'GT Walsheim Bold';
    font-display: swap;
    src: url('/assets/site/fonts/gt-walsheim-bold-web.ttf') format('truetype');
}

@font-face {
    font-family: 'GT Walsheim Light';
    font-display: swap;
    src: url('/assets/site/fonts/gt-walsheim-light-web.ttf') format('truetype');
}

@font-face {
    font-family: 'GT Walsheim Medium';
    font-display: swap;
    src: url('/assets/site/fonts/gt-walsheim-medium-web.ttf') format('truetype');
}

div.min-h-content{
    @apply flex-grow;
    /* min-height: 700px !important; */
}

.turbolinks-progress-bar {
    @apply bg-brown-400;
    height: 3px;
}

svg {
    display: inline-block !important;
}

.spin {
    animation: spin 2s infinite linear;
}

@keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}


.breadcrumb a:hover {
    @apply underline;
}

.blue {
    @apply bg-blue-300;
}

.green {
    @apply bg-green-600;
}

.beige {
    @apply bg-beige-300;
}

.slidertitle {
    height: 60px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

#werknemers-hero{
    min-height: 550px;
    background-image: url(/assets/site/img/illustraties/werkgever.jpg);
    background-position: center left 0;
    
}

@media (min-width: 768px) {
    #werknemers-hero{
        background-position: center left 50vw;
    }
}