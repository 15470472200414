@tailwind base;
@tailwind components;
@tailwind utilities;

@import "_general.css";
@import "_autocomplete.css";
@import '_choices.css';
@import "_styled-content.css";
@import '_toastify.css';
@import '../../../node_modules/pikaday/css/pikaday.css';

@responsive {
    .text-shadow {
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .text-shadow-md {
        text-shadow: 0 4px 8px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.08);
    }

    .text-shadow-lg {
        text-shadow: 0 15px 30px rgba(0, 0, 0, 0.11), 0 5px 15px rgba(0, 0, 0, 0.08);
    }

    .text-shadow-none {
        text-shadow: none;
    }
}

@responsive {
    .col-count-2 {
        column-count: 2;
    }
    .col-count-3 {
        column-count: 3;
    }
    .col-count-4 {
        column-count: 4;
    }
  }
