.styled-content,
.styled-content .fr-wrapper .fr-element.fr-view{
    @apply font-humboldt-light text-brown-900 text-base;
}

.styled-content a {
    @apply font-humboldt font-bold text-green-600
}

.styled-content p {
    @apply mt-3;
}

.styled-content .intro {
    @apply leading-loose text-xl;
}

.styled-content a {
    @apply text-green-600;
}

.styled-content a:hover {
    @apply underline;
}

.styled-content p {
    @apply mb-3;
}

.styled-content h2,
.styled-content h3,
.styled-content h4,
.styled-content h5 {
    @apply font-humboldt mt-3 mb-1 text-brown-500;
}

.styled-content h2 {
    @apply text-4xl;
}

.styled-content h3 {
    @apply text-3xl;
}

.styled-content h4 {
    @apply text-2xl;
}

.styled-content h5 {
    @apply text-xl text-brown-700;
}

.styled-content strong {
    @apply font-humboldt text-brown-700;
}

.styled-content ul {
    @apply list-disc ml-5 my-3;

}

.styled-content ol {
    @apply list-decimal ml-5 my-3;
}

.styled-content ul li {
    @apply mb-1;
}

.styled-content table {
    @apply table-auto w-full mb-3;
}

.styled-content table td {
    @apply p-2 border;
}

.styled-content img {
    @apply my-5;
}

/* responsive videos */
.styled-content iframe {
    border: none;
}

.styled-content .fr-video {
    @apply mb-5;
    display: block;
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden !important;
}

.styled-content .fr-video iframe,
.styled-content .fr-video object,
.styled-content .fr-video embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
