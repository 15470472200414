/* Algolia */
.algolia-places .ap-input-icon.ap-icon-pin {
  @apply hidden;
}

/* Autocomplete */
.autoComplete_wrapper {
    position: relative;
}

#autoComplete_list {
  @apply absolute bg-white rounded-b-lg shadow z-50 w-full z-50;
  transition: all 0.1s ease-in-out;
}

.autoComplete_result {
  @apply py-3 px-4;
  transition: all 0.1s ease-in-out;
}


.autoComplete_result:hover,
.autoComplete_selected {
  @apply bg-green-300 cursor-pointer;
}

.autoComplete_result .category{
  @apply text-sm text-brown-300 ml-1;
}

.autoComplete_result:hover .category,
.autoComplete_selected .category{
  @apply text-green-600;
}

.autoComplete_result:last-child {
  @apply rounded-b-lg;
}

.autoComplete_highlighted {
  @apply text-green-600;
}


/* Fix to hide on blur */
#searchAutoComplete + ul#autoComplete_list{
  display:none;

}
#searchAutoComplete:focus + ul#autoComplete_list {
  display: block;
}